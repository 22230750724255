
import { ProjectController, ClientController, EmployeeController, EmployeegroupController } from "@/controller";
import { Button, IconButton, SearchList, SelectSingleSearch} from "@/ui/index";
import { defineComponent } from "vue";
import { Client, Employee } from "@/model/index.ts";
import store from '@/store/index';

export default defineComponent({
    name: "ProjectForm",
    components: { Button, IconButton, SearchList, SelectSingleSearch },
    data(){
        return{
            store,
            editMode: false,
            form: {
                numberExternal: "",
                numberInternal: "",
                numberSerial: "",
                title: "",
                customer: {
                    uid: 0,
                    custName: ""
                },
                moneyBudget: 0,
                timeBudget: 0,
                salesTarget: 0,
                group: 0
            },
            clients: [] as Array<Client>,
            employees: [] as Array<Employee>,
            fetchedEmployeeGroups: [] as Array<any>
        }
    },
    computed:{
        getProfileImageSrc() {
            return ""
        },
        /*clientsDataForSearchList() {
            let array : any = []
            if(this.clients && this.clients.length > 0) {   
                this.clients.forEach((item:any) => {
                    let listItem = {
                        uid: item.uid,
                        text: item.custName
                    }
                   array.push(listItem)
                });
            }
            return array
        },*/
        employeeDataForSearchList(){
            let array : any = []

            if(this.employees && this.employees.length > 0){
                this.employees.forEach((item:any) => {
                    let listItem = {
                        uid: item.uid,
                        text: item.lastName + " " + item.firstName
                    }
                    array.push(listItem)
                });
             }
            return array
        }
    },
    created() {
        this.handleFetchEmployees();
        this.handleFetchEmployeeGroups();
        if (this.$route.params.id && this.$route.params.id != "0" ) {
            this.editMode = true;
            this.handleGetProject(this.$route.params.id);
        } else {
            this.editMode = false;
        }
        this.handleFetchClients();
    },
    methods:{
        handleGoBack() {
            this.$router.go(-1);
        },
        handleSetClient(item:any, value : number, text : string) {    
            this.form.customer.uid = value;
            this.form.customer.custName = text;
        },
        handleNewClientClick(input:any){
            this.form.customer = {
                uid: 0,
                custName: input
            }
        },
        handleSetGroup(value : number, text : string) {
            this.form.group = value;
        },
        handleSubmitPrevent() {
            if (this.editMode) {
                this.handleSave();
            } else {
                this.handleCreate();
            }
        },
        async handleGetProject(uid:any) {
            const res = await ProjectController.getProjectById(uid);
            if (!res.error) {
                this.form = res.project;
            } else {
                this.$notify(this.$t(res.error.message), { position: "right", type: "error" });
            }
        },
        async handleFetchClients() {
            const res = await ClientController.fetchClients();
            if(!res.error){
                this.clients = res.items;
            } else {
                this.$notify(this.$t(res.error.message), { position: "right", type: "error" });
            }
        },
        async handleFetchEmployees() {
            const res = await EmployeeController.fetchEmployees();
            if(!res.error){
                this.employees = res.items;
            } else {
                this.$notify(this.$t(res.error.message), { position: "right", type: "error" });
            }
        },
        async handleFetchEmployeeGroups() {
            let res : any  = await EmployeegroupController.fetchEmployeegroups();
            console.log("groups", res)
            if (res) {
                this.fetchedEmployeeGroups = [];
                console.log("fetchedEmployeeGroups", this.fetchedEmployeeGroups);
            }
        },
        async handleCreate() {
            const res = await ProjectController.createProject(this.form);
            if (!res.error) {
                this.$notify(this.$t("success.projectCreated"), { position: "top" });
                this.$router.push("/administration/project/detail/" + res.project.uid);
            } else {
                this.$notify(this.$t(res.error.message), { position: "right", type: "error" });
            }
        },
        async handleSave() {
            const res = await ProjectController.updateProject(this.form);
            if (!res.error) {
                this.form = res.project;
                this.$notify(this.$t("success.projectUpdated"), { position: "top" });
                this.$router.push("/administration/projects");
            } else {
                this.$notify(this.$t(res.error.message), { position: "right", type: "error" });
            }
        }
    }
})
