import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "timi-project-container" }
const _hoisted_2 = { class: "form-layout" }
const _hoisted_3 = { class: "timi-form-item col-4" }
const _hoisted_4 = ["placeholder"]
const _hoisted_5 = { class: "timi-form-item col-4" }
const _hoisted_6 = ["placeholder"]
const _hoisted_7 = { class: "timi-form-item col-4" }
const _hoisted_8 = ["placeholder"]
const _hoisted_9 = { class: "timi-form-item" }
const _hoisted_10 = ["placeholder"]
const _hoisted_11 = { class: "timi-form-item col-6" }
const _hoisted_12 = {
  key: 0,
  class: "timi-form-item col-6"
}
const _hoisted_13 = { class: "timi-form-item col-6" }
const _hoisted_14 = ["placeholder"]
const _hoisted_15 = { class: "timi-form-item col-6" }
const _hoisted_16 = ["placeholder"]
const _hoisted_17 = { class: "timi-form-item col-6" }
const _hoisted_18 = ["placeholder"]
const _hoisted_19 = { class: "form-footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IconButton = _resolveComponent("IconButton")!
  const _component_SelectSingleSearch = _resolveComponent("SelectSingleSearch")!
  const _component_SearchList = _resolveComponent("SearchList")!
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_IconButton, {
      icon: _ctx.store.getters.svgIcons.arrowbackward,
      class: "go-back-btn",
      label: _ctx.$t('button.back'),
      dense: true,
      onOnClick: _ctx.handleGoBack
    }, null, 8, ["icon", "label", "onOnClick"]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("form", {
        onSubmit: _cache[7] || (_cache[7] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.handleSubmitPrevent && _ctx.handleSubmitPrevent(...args)), ["prevent"]))
      }, [
        _createElementVNode("h2", null, _toDisplayString(_ctx.$t("project.projectData")), 1),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("label", null, _toDisplayString(_ctx.$t('label.projectNumberExternal')), 1),
          _withDirectives(_createElementVNode("input", {
            type: "text",
            placeholder: _ctx.$t('placeholder.projectNumberExternal'),
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.form.numberExternal) = $event))
          }, null, 8, _hoisted_4), [
            [_vModelText, _ctx.form.numberExternal]
          ])
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("label", null, _toDisplayString(_ctx.$t('label.projectNumberInternal')), 1),
          _withDirectives(_createElementVNode("input", {
            type: "text",
            placeholder: _ctx.$t('placeholder.projectNumberInternal'),
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.form.numberInternal) = $event))
          }, null, 8, _hoisted_6), [
            [_vModelText, _ctx.form.numberInternal]
          ])
        ]),
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("label", null, _toDisplayString(_ctx.$t('label.projectNumberSerial')), 1),
          _withDirectives(_createElementVNode("input", {
            type: "text",
            placeholder: _ctx.$t('placeholder.projectNumberSerial'),
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.form.numberSerial) = $event))
          }, null, 8, _hoisted_8), [
            [_vModelText, _ctx.form.numberSerial]
          ])
        ]),
        _createElementVNode("div", _hoisted_9, [
          _createElementVNode("label", null, _toDisplayString(_ctx.$t('label.title')), 1),
          _withDirectives(_createElementVNode("input", {
            type: "text",
            placeholder: _ctx.$t('placeholder.title'),
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.form.title) = $event))
          }, null, 8, _hoisted_10), [
            [_vModelText, _ctx.form.title]
          ])
        ]),
        _createElementVNode("div", _hoisted_11, [
          _createElementVNode("h2", null, _toDisplayString(_ctx.$t('project.client')), 1),
          _createVNode(_component_SelectSingleSearch, {
            placeholder: _ctx.$t('placeholder.searchClient'),
            item: {},
            options: _ctx.clients,
            labelProperty: "custName",
            value: { value: _ctx.form.customer.uid, text: _ctx.form.customer.custName },
            allowCreateNew: true,
            emptyStateText: _ctx.$t('emptyState.clients'),
            onOnChange: _ctx.handleSetClient,
            onOnAddItemClicked: _ctx.handleNewClientClick
          }, null, 8, ["placeholder", "options", "value", "emptyStateText", "onOnChange", "onOnAddItemClicked"])
        ]),
        false
          ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
              _createElementVNode("h2", null, _toDisplayString(_ctx.$t('project.addEmployee')), 1),
              _createVNode(_component_SearchList, {
                placeholder: _ctx.$t('placeholder.searchEmployee'),
                item: {},
                options: _ctx.employeeDataForSearchList,
                secondOptionGroup: _ctx.fetchedEmployeeGroups,
                value: {},
                subheader: [{text: _ctx.$t('label.employee')}, {text: _ctx.$t('label.employeeGroup')}],
                onOnChange: _ctx.handleSetGroup
              }, null, 8, ["placeholder", "options", "secondOptionGroup", "subheader", "onOnChange"])
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("h2", null, _toDisplayString(_ctx.$t('label.budget')), 1),
        _createElementVNode("div", _hoisted_13, [
          _createElementVNode("label", null, _toDisplayString(_ctx.$t('label.budgetMoney')), 1),
          _withDirectives(_createElementVNode("input", {
            type: "text",
            placeholder: _ctx.$t('placeholder.budgetMoney'),
            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.form.moneyBudget) = $event))
          }, null, 8, _hoisted_14), [
            [_vModelText, _ctx.form.moneyBudget]
          ])
        ]),
        _createElementVNode("div", _hoisted_15, [
          _createElementVNode("label", null, _toDisplayString(_ctx.$t('label.budgetTime')), 1),
          _withDirectives(_createElementVNode("input", {
            type: "text",
            placeholder: _ctx.$t('placeholder.budgetTime'),
            "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.form.timeBudget) = $event))
          }, null, 8, _hoisted_16), [
            [_vModelText, _ctx.form.timeBudget]
          ])
        ]),
        _createElementVNode("div", _hoisted_17, [
          _createElementVNode("label", null, _toDisplayString(_ctx.$t('label.plannedTurnover')), 1),
          _withDirectives(_createElementVNode("input", {
            type: "text",
            placeholder: _ctx.$t('placeholder.plannedTurnover'),
            "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.form.salesTarget) = $event))
          }, null, 8, _hoisted_18), [
            [_vModelText, _ctx.form.salesTarget]
          ])
        ])
      ], 32)
    ]),
    _createElementVNode("div", _hoisted_19, [
      (!_ctx.editMode)
        ? (_openBlock(), _createBlock(_component_Button, {
            key: 0,
            text: _ctx.$t('button.addProject'),
            primary: true,
            raised: true,
            onOnClick: _ctx.handleCreate
          }, null, 8, ["text", "onOnClick"]))
        : (_openBlock(), _createBlock(_component_Button, {
            key: 1,
            text: _ctx.$t('button.save'),
            primary: true,
            raised: true,
            onOnClick: _ctx.handleSave
          }, null, 8, ["text", "onOnClick"]))
    ])
  ]))
}